
.mat-menu-panel {
  max-width: 90vw !important;
  border-radius: 12px !important;
  margin: 4px !important;
}

.mat-menu-item {
  font-family: "HelveticaNeue", sans-serif !important;
  padding: 10px 16px !important;
  font-size: 12px;
  height: auto !important;
  line-height: normal !important;
}
